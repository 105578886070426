import getConfig from 'next/config';

const resolveImagePath = (src: string, filter = 'index_medium') => {
    if (!src) return;
    const { apiUrl } = getConfig().publicRuntimeConfig;
    if (src.startsWith('build/images')) {
        return apiUrl + '/' + src;
    }
    return `${apiUrl}/media/cache/resolve/${filter}${encodeURIComponent(src)}`;
};

export default resolveImagePath;
